export function setToken(token) {
    localStorage.setItem('token', JSON.stringify(token));
}

export function getToken() {
    return localStorage.getItem('token');
}

export function removeToken() {
    localStorage.removeItem('token');
}

const allowedStatuses = ['Требуется подпись', 'Не востребован'];
export function checkAbleToSignOrDeclineStatus(documents) {
    const checkedDocuments = documents.filter((item) => item.checked);
    if (!checkedDocuments.length) return false;
    return checkedDocuments.every((item) => allowedStatuses.includes(item.recipientDocumentStatus) && !item.recipientSignatureStatus);
}

export function checkAbleToRevalidateStatus(documents) {
    const checkedDocuments = documents.filter((item) => item.checked);
    if (!checkedDocuments.length) return false;
    return checkedDocuments.every((item) => {
        return (
            item.recipientSignatureStatus === 'Не валидирована' ||
            item.recipientSignatureStatus === 'Валидирована' ||
            item.senderSignatureStatus === 'Не валидирована' ||
            item.senderSignatureStatus === 'Валидирована'
        );
    });
}
